import { fetchWrapperPortal } from "./fetch";

export const startTranscriptionProcessing = async (streamId) =>
    await fetchWrapperPortal({
      url: `transcriptions/${streamId}/start`,
      method: 'POST',
      addBearer: true,
      body: JSON.stringify({}),
    });
  
export const fetchTranscription = async (streamId) =>
    await fetchWrapperPortal({
      url: `transcriptions/${streamId}`,
      method: 'GET',
      addBearer: true,
    });

export const updateWord = async (streamId, updatedWord) => await fetchWrapperPortal({
    url: `transcriptions/${streamId}/update-word`,
    method: 'POST',
    addBearer: true,
    body: JSON.stringify({updatedWord}),
  });