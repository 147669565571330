import { needsRefresh, refreshAuth } from './auth';

const portal_endpoint = "https://portal-api.hotmic.io"; // [PROD]
// const portal_endpoint = "https://portal-test.api.hotmic.io"; // [Cloud9] - TODO change this back to Prod before deploy
// const portal_endpoint = "https://portal-dev.api.hotmic.io"; // [STAGING] - TODO change back to prod before deploy

let refreshPromise = null;

// Add list of public endpoints that don't require auth
const PUBLIC_ENDPOINTS = [
  'auth/login',
  'auth/oauth/login',
  'auth/password/reset',
  'admin/theme',  // Add the theme endpoint to public endpoints
  'users/me',     // Add the user refresh endpoint
];

export const fetchWrapperPortal = async ({ ...args }) => {
  try {
    // Check if endpoint requires auth
    const isPublicEndpoint = PUBLIC_ENDPOINTS.some(endpoint => args.url.startsWith(endpoint));

    // Only check for refresh if not a public endpoint and not a refresh request
    if (!isPublicEndpoint && args.url !== 'auth/refresh/' && needsRefresh()) {
      try {
        // Use a single refresh promise to prevent multiple simultaneous refresh attempts
        if (!refreshPromise) {
          refreshPromise = refreshAuth();
        }
        await refreshPromise;
        refreshPromise = null;
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        // Clear refresh promise on error
        refreshPromise = null;
        // Clear all auth state
        localStorage.clear();
        // Only redirect if we're not already heading to login
        if (!window.location.pathname.includes('/auth/login')) {
          window.location.href = '/auth/login';
        }
        throw refreshError;
      }
    }

    const fetchObj = {};
    fetchObj.headers = args.headers || {};
    fetchObj.method = args.method.toUpperCase();
    fetchObj.body = args.body ? args.body : null;
    fetchObj.headers["Content-Type"] = "application/json";
    fetchObj.headers["ApiKey"] = process.env.REACT_APP_APP_KEY;
    
    // Add auth header if token exists, even for public endpoints
    if (!fetchObj.headers.Authorization && args.addBearer) {
      const token = args.token || localStorage.getItem("access_token");
      if (token) {
        fetchObj.headers.Authorization = args.token ? args.token : `Bearer ${token}`;
      } else if (!isPublicEndpoint) {
        throw new Error('No authorization token available');
      }
    }

    if (args.signal) {
      fetchObj.signal = args.signal;
    }

    const result = await fetch(`${portal_endpoint}/${args.url}`, fetchObj);
    // Handle 401/403 responses only for non-public endpoints
    if (!isPublicEndpoint && (result.status === 401 || result.status === 403)) {
      // Track consecutive auth errors within a short time window
      const now = Date.now();
      const lastAuthError = parseInt(localStorage.getItem('last_auth_error') || '0');
      const authErrorCount = parseInt(localStorage.getItem('auth_error_count') || '0');
      
      // If last error was more than 15 minutes ago, reset counter
      if (now - lastAuthError > 15 * 60 * 1000) {
        localStorage.setItem('auth_error_count', '1');
      } else {
        localStorage.setItem('auth_error_count', (authErrorCount + 1).toString());
      }
      localStorage.setItem('last_auth_error', now.toString());
      
      // Only clear auth state and redirect if we get persistent auth errors
      // (5+ errors within 15 minutes)
      if (authErrorCount >= 5) {
        console.error('Persistent authentication errors detected, logging out');
        localStorage.clear();
        if (!window.location.pathname.includes('/auth/login')) {
          window.location.href = '/auth/login';
        }
      } else {
        console.warn('Temporary auth error, will retry');
      }
      
      throw new Error(`Authentication error: ${result.status}`);
    }

    // Check if response is JSON
    const contentType = result.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      console.error('Non-JSON response received:', {
        contentType,
        status: result.status,
        statusText: result.statusText
      });
      const text = await result.text();
      console.error('Response text:', text);
      throw new Error(`Expected JSON response but got ${contentType}`);
    }
    
    return result && result.status === 204 ? result.status : await result.json();
  } catch (e) {
    console.error(`HTTP ERROR:`, e);
    // Don't rethrow network errors or temporary auth errors
    if (e.name === 'TypeError' || e.name === 'NetworkError' || 
        (e.message && e.message.includes('Authentication error'))) {
      console.warn('Network/auth error occurred, might be temporary');
      return { error: e.message || 'Network error occurred' };
    }
    throw e;
  }
};
