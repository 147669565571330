import React, { createContext, useContext, useState, useEffect } from 'react';
import { adminAPI } from '../portal-apis';

const ThemeContext = createContext();

export const DEFAULT_APP_ID = '09c935f5-95f3-4c47-9ca8-5d90da6f455c';

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({
    app_id: DEFAULT_APP_ID,
    colors: {
      primary: '#6927f4',
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.65)',
        heading: 'rgba(255, 255, 255, 0.90)'
      },
      background: {
        base: '#000000',
        primary: '#0A0A0A',
        secondary: '#22242a',
        modal: '#1a1a1a',
        input: '#27262b',
        hover: '#2c2c2f',
        spotlightmask: 'rgba(0, 0, 0, 0.7)'
      },
      surface: {
        level1: '#121212',
        level2: '#1A1A1A',
        level3: '#1f1f1f',
        focused: '#3D3C41'
      },
      border: '#48474D',
      error: '#f5222d',
      warning: {
        main: '#FAAD14'
      },
      link: '#1890ff'
    },
    logo: {
      primary: '/logo_br.svg',
      favicon: '/favicon.ico'
    }
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTheme = async () => {
      try {
        const subdomain = window.location.hostname.split('.')[0];
        
        // Check localStorage first
        const cachedTheme = localStorage.getItem(`theme_${subdomain}`);
        if (cachedTheme) {
          const parsedTheme = JSON.parse(cachedTheme);
          setTheme(parsedTheme);
          updateFavicon(parsedTheme);
          setLoading(false);
          return;
        }

        // If no cached theme, fetch from API
        const loadedTheme = await adminAPI.getThemeBySubdomain(subdomain);
        // Cache the theme
        localStorage.setItem(`theme_${subdomain}`, JSON.stringify(loadedTheme));
        setTheme(loadedTheme);
        updateFavicon(loadedTheme);
      } catch (error) {
        console.error('Error loading theme:', error);
        setTheme({ app_id: DEFAULT_APP_ID });
      } finally {
        setLoading(false);
      }
    };

    const updateFavicon = (themeData) => {
      const favicon = document.querySelector("link[rel='icon']");
      if (favicon && themeData?.logo?.favicon) {
        favicon.href = themeData.logo.favicon;
      }
    };

    loadTheme();
  }, []);

  const value = {
    theme,
    setTheme,
    loading,
    app_id: theme?.app_id || DEFAULT_APP_ID
  };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const useAppId = () => {
  const { app_id } = useTheme();
  return app_id;
}; 