import React, { getGlobal, useGlobal } from "reactn";
import { Navigate, useLocation } from "react-router-dom"; // Removed Route import
import ProLayout from "@ant-design/pro-layout";
import { Layout } from "antd";

import { useGlobalLoader, GlobalLoader } from "utils/global";
import { useTheme } from 'utils/global/ThemeContext';
import { authAPI, userAPI } from "utils/portal-apis";

import { defaultSettings, getMenuRoutes, RightContent } from "./common";
import { useEffect, useState } from "react";
import styled from "styled-components";

// Adjust the StyledProLayout styling if necessary for the top bar layout
const StyledProLayout = styled(ProLayout)`
  max-width: none !important;
  width: 100vw !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .ant-pro-top-nav-header {
    background: #000000 !important;
    border-bottom: 1px solid var(--border-default, #3D3C41);
  }

  .ant-menu {
    background: transparent !important;
  }

  .ant-menu-item,
  .ant-menu-item a,
  .ant-menu-item span {
    font-family: "Druk Text" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
    color: #FFFFFF !important;
  }

  .ant-menu-submenu-title,
  .ant-menu-submenu-title span {
    font-family: "Druk Text" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 120% !important;
    color: #FFFFFF !important;
  }

  .ant-menu-item {
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }

  .ant-menu-item-selected a {
    position: relative !important;
    
    &:after {
      content: '' !important;
      position: absolute !important;
      left: 0 !important;
      right: 0 !important;
      bottom: -5px !important;
      height: 3px !important;
      background: #FFFFFF !important;
    }
  }

  .ant-menu-item::after {
    display: none !important;
  }
`;

function PrivateRoute({ children, noHeader = false }) {
  console.log('=== START PrivateRoute component rendering ===');
  const { loading } = useGlobalLoader();
  const [authenticated, setAuthenticated] = useState(true);
  const [isLive] = useGlobal("isLive");
  const { theme } = useTheme();
  console.log('PrivateRoute theme:', theme);
  console.log('PrivateRoute isLive:', isLive);
  
  let location = useLocation();
  console.log('PrivateRoute location:', location);

  useEffect(() => {
    console.log('=== START PrivateRoute useEffect for authentication ===');
    userAPI.refreshUser().then((result) => {
      console.log('User refresh result:', result);
      setAuthenticated(!!result && !!result._id);
    });
    console.log('=== END PrivateRoute useEffect for authentication ===');
  }, []);

  if (authenticated && authAPI.isLoggedIn()) {
    console.log('=== User is authenticated in PrivateRoute ===');
    const user = getGlobal();
    console.log('User from global state:', user);
    
    if (user.show_portal_onboarding) {
      console.log('Redirecting to onboarding');
      localStorage.setItem("redirectURL", location.pathname);
      return <Navigate to="/auth/onboarding" />;
    }
    
    const menuRoutes = isLive ? getMenuRoutes(true) : getMenuRoutes(false);
    console.log('Menu routes generated:', menuRoutes);
    
    // Create a safe theme object with fallbacks for any missing properties
    const safeTheme = {
      ...theme,
      colors: {
        ...(theme?.colors || {}),
        primary: theme?.colors?.primary || '#6927f4',
        surface: {
          ...(theme?.colors?.surface || {}),
          level1: theme?.colors?.surface?.level1 || '#121212',
          level2: theme?.colors?.surface?.level2 || '#1A1A1A',
          level3: theme?.colors?.surface?.level3 || '#1f1f1f',
          focused: theme?.colors?.surface?.focused || '#3D3C41'
        }
      },
      logo: {
        ...(theme?.logo || {}),
        primary: theme?.logo?.primary || '/logo_br.svg',
        favicon: theme?.logo?.favicon || '/favicon.ico'
      },
      typography: {
        ...(theme?.typography || {}),
        fontFamily: {
          ...(theme?.typography?.fontFamily || {}),
          primary: theme?.typography?.fontFamily?.primary || '"Proxima Nova", Helvetica, Arial, sans-serif',
          secondary: theme?.typography?.fontFamily?.secondary || '"Maison Neue", Helvetica, Arial, sans-serif',
          mono: theme?.typography?.fontFamily?.mono || '"Maison Neue Mono", "Maison Neue", Helvetica, Arial, sans-serif'
        }
      },
      auth: {
        ...(theme?.auth || {}),
        ssoEnabled: theme?.auth?.ssoEnabled !== undefined ? theme?.auth?.ssoEnabled : false,
        ssoConfig: {
          ...(theme?.auth?.ssoConfig || {}),
          provider: theme?.auth?.ssoConfig?.provider || 'bleacherReport',
          displayName: theme?.auth?.ssoConfig?.displayName || 'Bleacher Report',
          loginButtonText: theme?.auth?.ssoConfig?.loginButtonText || 'Login with Bleacher Report',
          url: theme?.auth?.ssoConfig?.url || 'https://prd.api.bleacherreport.com/creator-login',
          clientId: theme?.auth?.ssoConfig?.clientId || '1ec9bfff-6731-49f3-a9be-2c7e6f0f790c',
          redirectUrl: theme?.auth?.ssoConfig?.redirectUrl || 'https://staging.app.hotmic.io/login/oauth/callback',
          popupWidth: theme?.auth?.ssoConfig?.popupWidth || 600,
          popupHeight: theme?.auth?.ssoConfig?.popupHeight || 700
        }
      }
    };
    
    console.log('About to render ProLayout with settings:', {
      ...Object.assign({}, defaultSettings, { 
        logo: safeTheme?.logo?.primary || defaultSettings.logo, 
        layout: 'top', 
        headerRender: noHeader ? false : undefined 
      })
    });
    
    try {
      return (
        <GlobalLoader loading={loading}>
          <StyledProLayout
            {...Object.assign({}, defaultSettings, { 
              logo: safeTheme?.logo?.primary || defaultSettings.logo, 
              layout: 'top', 
              headerRender: noHeader ? false : undefined 
            })}
            route={menuRoutes}
            rightContentRender={() => <RightContent isPublicPage={false} isLive={isLive} />}
            isPublicPage={false}
            isLive={isLive}
          >
            <Layout>
              <Layout.Content>
                {children}
              </Layout.Content>
            </Layout>
          </StyledProLayout>
        </GlobalLoader>
      );
    } catch (error) {
      console.error('=== ERROR in ProLayout rendering ===');
      console.error('Error details:', error);
      console.error('Error stack:', error.stack);
      console.error('menuRoutes:', menuRoutes);
      console.error('theme:', theme);
      console.error('isLive:', isLive);
      
      // Fallback to a simple layout without ProLayout
      return (
        <GlobalLoader loading={loading}>
          <div style={{ padding: '20px' }}>
            <h1>Error in layout rendering</h1>
            <p>There was an error rendering the layout. Please check the console for details.</p>
            <div>{children}</div>
          </div>
        </GlobalLoader>
      );
    }
  } else {
    console.log('=== User is NOT authenticated in PrivateRoute ===');
    localStorage.setItem("redirectURL", location.pathname);
    return <Navigate to="/auth/login" />;
  }
}

export default PrivateRoute;
