import React, { getGlobal } from "reactn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faCog } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Menu, Dropdown, Button } from "antd";
import styled from "styled-components";

import { authAPI } from "utils/portal-apis";
import { SignIn } from "components/Auth";

export const defaultSettings = {
  logo: "/logo_br.svg",
  navTheme: "light",
  layout: "topmenu",
  fixedHeader: true,
  contentWidth: "Fixed",
  title: "",
  style: {
    minHeight: "100vh",
  },
  menuItemRender: (menuItemProps, defaultDom) => {
    if (menuItemProps.path.indexOf("http") > -1) {
      return ( // TODO: Maybe use react router dom link instead here for a more efficient navigation
        <a
          href={menuItemProps.path}
          target={menuItemProps.target || "_self"}
          rel={menuItemProps.target === "_blank" ? "noopener noreferrer" : undefined}
        >
          {defaultDom}
        </a>
      );
    }
    return <a href={menuItemProps.path}>{defaultDom}</a>;
  },
};

export const publicDefaultSettings = {
  logo: "/logo_br.svg",
  navTheme: "light",
  layout: "topmenu",
  fixedHeader: true,
  contentWidth: "wrap",
  title: "",
  style: {
    minHeight: "100vh",
  },
  menuItemRender: (menuItemProps, defaultDom) => {
    if (menuItemProps.path.indexOf("http") > -1) {
      return defaultDom;
    }
    return <a href={menuItemProps.path}>{defaultDom}</a>;
  },
};

const StyledDropdown = styled(Dropdown)`
  &.live {
    cursor: default !important;
  }
`;

export const getMenuRoutes = (isLive) => {
  console.log('=== START getMenuRoutes ===');
  console.log('getMenuRoutes called with isLive:', isLive);
  
  try {
    const loggedInUser = getGlobal() || {};
    console.log('loggedInUser in getMenuRoutes:', loggedInUser);
    
    // Ensure user permissions exist with defaults
    const safeUser = {
      ...loggedInUser,
      show_producer_tools: loggedInUser.show_producer_tools || false,
      show_moderation_tools: loggedInUser.show_moderation_tools || false,
      show_programming_tools: loggedInUser.show_programming_tools || false,
      show_developer_tools: loggedInUser.show_developer_tools || false,
      can_manage_org: loggedInUser.can_manage_org || false
    };
    
    const isBroadcastPage =
      window.location.href.indexOf("/broadcast/") > 0 && window.location.href.indexOf("/broadcast/my") < 0;
    const domain = new URL(window.location.href).origin;
    
    console.log('isBroadcastPage:', isBroadcastPage);
    console.log('domain:', domain);
    console.log('Safe user permissions:', {
      show_producer_tools: safeUser.show_producer_tools,
      show_moderation_tools: safeUser.show_moderation_tools,
      show_programming_tools: safeUser.show_programming_tools,
      show_developer_tools: safeUser.show_developer_tools,
      can_manage_org: safeUser.can_manage_org
    });

    let routes;
    if (isBroadcastPage) {
      console.log('Generating broadcast page routes');
      routes = {
        path: "/",
        routes: [
          {
            key: "dashboard",
            path: `/broadcast/my`,
            name: "Dashboard",
            target: "_blank",
          },
        ],
      };
    } else {
      console.log('Generating standard routes');
      routes = {
        path: "/",
        routes: [
          {
            key: "dashboard",
            path: `/broadcast/my`,
            name: "Dashboard",
          },
          {
            key: "graphics",
            path: "/media",
            name: "Graphics",
            hideInMenu: isBroadcastPage,
          },
          {
            key: "producers",
            name: "Producers",
            hideInMenu: !safeUser.show_producer_tools && !safeUser.show_moderation_tools,
            children: [
              {
                key: "active-events",
                path: `${domain}/event/active`,
                name: "Stream Manager",          
                target: "_self"
              },
              {
                key: "moderation",
                path: `${domain}/moderation`,
                name: "Moderation",          
                hideInMenu: !safeUser.show_moderation_tools,
                target: "_self"
              },
            ],
          },
          {
            key: "programmers",
            name: "Programmers",
            hideInMenu: !safeUser.show_programming_tools,
            children: [
              {
                key: "live-programming",
                path: `${domain}/event/active?programmingType=streamSlicer`,
                name: "StreamSlicer (BETA)",
                target: "_self"
              },
              {
                key: "clips",
                path: `${domain}/allclips`,
                name: "Clip Manager",
                target: "_self"
              },
            ],
          },
          {
            key: "developers",
            name: "Developers",
            hideInMenu: !safeUser.show_developer_tools,
            children: [
              {
                key: "webhooks",
                path: `${domain}/webhooks`,
                name: "Webhooks"          },
              {
                key: "web-embed",
                path: `${domain}/web-embed`,
                name: "Web Embed"          },
              {
                key: "api-docs",
                path: "https://docs.hotmic.io/api",
                name: "API Documentation",
                target: "_blank",
              },
            ],
          },
          {
            key: "admins",
            name: "Admins",
            hideInMenu: !safeUser.can_manage_org,
            children: [
              {
                key: "users",
                path: `${domain}/user/list`,
                name: "Users"
              },
              // {
                //   key: "theme",
                //   path: `${domain}/theme`,
                //   name: "Studio Settings"
                // },
            ],
          },
          {
            key: "help",
            path: "https://brcreators.zendesk.com/hc/en-us",
            name: "Help",
            target: "_blank",
          },
          {
            key: "settings",
            path: "/settings/:name",
            name: "Settings",
            hideInMenu: true,
          }
        ],
      };
    }
    
    console.log('Final routes object:', routes);
    console.log('=== END getMenuRoutes ===');
    return routes;
  } catch (error) {
    console.error('=== ERROR in getMenuRoutes ===');
    console.error('Error details:', error);
    console.error('Error stack:', error.stack);
    
    // Return a minimal routes object as fallback
    return {
      path: "/",
      routes: [
        {
          key: "dashboard",
          path: `/broadcast/my`,
          name: "Dashboard",
        },
        {
          key: "help",
          path: "https://brcreators.zendesk.com/hc/en-us",
          name: "Help",
          target: "_blank",
        }
      ]
    };
  }
};

export function RightContent({ isPublicPage, showSignIn, signInSetter, isLive }) {
  console.log('=== START RightContent function ===');
  console.log('RightContent props:', { isPublicPage, showSignIn, isLive });
  
  try {
    const loggedInUser = getGlobal() || {};
    console.log('loggedInUser in RightContent:', loggedInUser);
    
    const isBroadcastPage =
      window.location.href.indexOf("/broadcast/") > 0 && window.location.href.indexOf("/broadcast/my") < 0;
    console.log('isBroadcastPage in RightContent:', isBroadcastPage);

    const actionSelect = ({ key }) => {
      console.log('Menu item selected:', key);
      if (key === "logout") {
        authAPI.logout();
      }
    };

    const menuHeaderDropdown = (
      <Menu onClick={actionSelect}>
        {isPublicPage !== true && (
          <Menu.Item key="settings">
            <a href="/settings">
              <FontAwesomeIcon icon={faCog} className="anticon" fixedWidth /> Settings
            </a>
          </Menu.Item>
        )}
        {isPublicPage !== true && !isBroadcastPage && <Menu.Divider />}
        {!isBroadcastPage && (
          <Menu.Item key="logout">
            {isPublicPage !== true && (
              <a
                href={{
                  pathname: "/",
                  search: window.location.search,
                }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="anticon" fixedWidth /> Logout
              </a>
            )}
            {isPublicPage === true && (
              <a
                href={{
                  search: window.location.search,
                }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="anticon" fixedWidth /> Logout
              </a>
            )}
          </Menu.Item>
        )}
      </Menu>
    );

    console.log('authAPI.isLoggedIn():', authAPI.isLoggedIn());
    
    if (authAPI.isLoggedIn()) {
      console.log('Rendering logged-in user dropdown');
      const profilePic = loggedInUser.profile_pic || "https://hotmic-content.s3-us-west-1.amazonaws.com/users/default-profile.png";
      const userName = loggedInUser.name || "User";
      
      return (
        <StyledDropdown
          overlay={menuHeaderDropdown}
          disabled={isLive}
          // arrow={!isLive}
          className={isLive ? "live" : ""}
        >
          <span style={{ height: "64px", display: "block", cursor: "pointer" }}>
            <Avatar src={profilePic} />
            <span style={{ margin: "0 10px" }}>{userName}</span>
          </span>
        </StyledDropdown>
      );
    } else {
      console.log('Rendering sign-in button');
      return (
        <span>
          <Button type="primary" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => signInSetter ? signInSetter(true) : null}>
            Sign In
          </Button>
          {showSignIn && <SignIn showModal={showSignIn} onCancelled={() => signInSetter ? signInSetter(false) : null} />}
        </span>
      );
    }
  } catch (error) {
    console.error('=== ERROR in RightContent function ===');
    console.error('Error details:', error);
    console.error('Error stack:', error.stack);
    
    // Return a simple fallback
    return (
      <span>
        <Button type="primary" style={{ paddingLeft: 20, paddingRight: 20 }}>
          Menu
        </Button>
      </span>
    );
  }
}
