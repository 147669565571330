import React from "reactn";
import ProLayout from "@ant-design/pro-layout";
import { Layout } from "antd";
import styled from "styled-components";

import { useGlobalLoader, GlobalLoader } from "utils/global";
import { useTheme } from 'utils/global/ThemeContext';


import { defaultSettings } from "./common";

// Adjust the StyledProLayout styling if necessary for the auth layout
const StyledProLayout = styled(ProLayout)`
  // Adjust or remove custom styles to fit the auth layout
`;

function AuthRoute({ children }) {
  const { loading } = useGlobalLoader();
  const { theme } = useTheme();

  return (
    <GlobalLoader loading={loading}>
      <StyledProLayout {...Object.assign({}, defaultSettings, { logo: theme?.logo?.primary, layout: 'top' })}>
        <Layout>
          <Layout.Content>
            {children}
          </Layout.Content>
        </Layout>
      </StyledProLayout>
    </GlobalLoader>
  );
}

export default AuthRoute;
